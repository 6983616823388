

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ErrorMessageService } from '../error-message/error-message.component.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
    form!: FormGroup;
    submitted = false;
    error:string|null= null;
    @Output() save: EventEmitter<{ dob: string }> =
    new EventEmitter<{ dob: string}>();
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private errorMessageService: ErrorMessageService
  ) {
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
            year: ['', [
              Validators.required,
              // validates date format YYYY
              Validators.pattern(/^\d{4}$/)
            ]],
            month: ['', [
              Validators.required,
              Validators.pattern(/^(0?[1-9]|1[0-2])$/)
            ]],
    });
  }
onSubmit(val:boolean) {
  if(val){
    this.save.emit({dob:`2000-12`});
  }else{
    this.save.emit({dob:`2024-12`});
  }
}
pad(num: number): string {
  return num < 10 ? `0${num}` : num.toString();
}


}
