@switch (this.messageInput.content) {
@case ('terms') {
<div class="message-cover animate__animated animate__bounceIn"
  [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'">
  <p>But first I have to ask you - press accept to tell me you’ve read and agree with the <span (click)="showTerms()"
      class="underline">Terms
      and Conditions</span>.
  </p>
</div>
<div class="button-container animate__animated animate__bounceIn">
  <div>
    <button id="decline-button" (click)="declineTerms((messageInput._id || ''), $event)" [ngClass]="messageInput.acknowledged?'opac':''" [disabled]="messageInput.acknowledged||false"
      class="app-cta interstate terms_and_conditions_declined">Decline</button>
  </div>
  <div>
    <button id="accept-button" (click)="acceptTerms((messageInput._id || ''), $event)" [ngClass]="messageInput.acknowledged?'opac':''" [disabled]="(messageInput.acknowledged)||false"
      class="app-cta interstate terms_and_conditions_accepted">Accept</button>
  </div>
</div>
}
@case ('watch') {
<div class="message-cover animate__animated animate__bounceIn"
  [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'">
  <p>The video is being prepared. Do you want to have a look?</p>
</div>
<div class="button-container animate__animated animate__bounceIn">
  <button (click)="watchLive()" class="app-cta btn-big-text tungsten">WATCH LIVE</button>
</div>
}
@case ('snickers') {
<div class="message-cover animate__animated animate__bounceIn"
  [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'">
  <p>One last thought from me… Maybe you should get your friend a Snickers…</p>
</div>
<div class="button-container animate__animated animate__bounceIn">
  <button (click)="getSnickers()" class="app-cta btn-big-text tungsten">GET A SNICKERS</button>
</div>
}
@case ('restart') {
<div class="message-cover animate__animated animate__bounceIn"
  [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'">
  <p>Got another own goal to tell me about?</p>
</div>
<div class="button-container animate__animated animate__bounceIn">
  <button (click)="getSnickers()" class="app-cta btn-big-text tungsten">Start Again</button>
</div>
}
}