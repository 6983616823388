import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorMessageService {
    private $errorMessages: BehaviorSubject<{type:string, message:string}[] | null> = new BehaviorSubject<{type:string, message:string}[] | null>(null);
    public errorMessages$: Observable<{type:string, message:string}[] | null> = this.$errorMessages.asObservable();
    constructor() { }
    statusCheck(): void {
        const currentMessages = this.$errorMessages.getValue();
        if (currentMessages && currentMessages.length > 0) {
            const intervalSubscription = interval(5000).pipe(
                take(currentMessages.length),
                tap(() => this.removeFirstMessage())
            ).subscribe();

            this.$errorMessages.subscribe((messages) => {
                if (!messages || messages.length === 0) {
                    intervalSubscription.unsubscribe();
                }
            });
        }
    }
    addMessage(type:string, message:string): void {
        const currentMessages = this.$errorMessages.getValue();
        const newMessage = { type, message };

        if (currentMessages) {
            this.$errorMessages.next([...currentMessages, newMessage]);
        } else {
            this.$errorMessages.next([newMessage]);
        }
        this.statusCheck();
    }
    removeFirstMessage(): void {
        const currentMessages = this.$errorMessages.getValue();
        if (currentMessages && currentMessages.length > 0) {
            currentMessages.shift();
            this.$errorMessages.next([...currentMessages]);
        }
    }
}

