@if(!this.test && this.messageInput.type !== "hidden"){
  <div class="message-container animate__animated animate__bounceIn"
    [ngClass]="{'user': this.messageInput.role === 'user'}">
    @if(this.messageInput.role !== 'user') {
    <img src="/assets/images/MsgTailJose.svg" class="tail-svg-jose">
    } @else {
    <img src="/assets/images/MsgTailUser.svg" class="tail-svg-user">
    }
    @if(isGifMessage()) {
    <div class="message-video-cover animate__animated animate__bounceIn"
      [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'" (click)="pushSaftey(messageInput)">
      <div class="video-wrapper">
        @if (getGifImage() !== ""){
          <img class="thumb" style="width: 250px;height:250px;" [src]="getGifImage()">
        }
        <p class="msg-text" >{{getGifText()}}</p>
      </div>
    </div>
    } @else {
    <div class="message-video-cover animate__animated animate__bounceIn"
      [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'" (click)="pushSaftey(messageInput)">
      <div (click)="openModal()" class="video-wrapper">
        @if(this.index !== 0) {
          <img class="thumb" src="/assets/images/thumbnail-own-goal.png">
        } @else {
          <img class="thumb" src="/assets/images/intro-thumbnail-2.jpg">
        }
        <img class="play-icon" src="/assets/images/play.svg">
      </div>
      @if (this.index !== 0){
        <p class="msg-text">Ok. I'm ready to let your friend know my final thoughts...</p>
      }
    </div>
    }
  </div>
  } @else  {
    @if(this.test){
      <div class="message-cover animate__animated animate__bounceIn"
        [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'" (click)="pushSaftey(messageInput)">
        <div class="video-wrapper">
          <p class="msg-text">{{this.messageInput.content}}</p>
        </div>
      </div>
    }
  
  }
