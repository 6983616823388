<ng-container *ngIf="query | async; let query">
    @for(message of query.chat_history; track $index) {
        <app-message (newMessage)="newMessageCallback()" [messageInput]="message" [index]="$index" [test]="query.is_system||false" (saftey)="pushSaftey($event)"></app-message>
    }
    <div class="message-received loading animate__animated animate__bounceIn" *ngIf="loading | async; let loading">
        <div class="loader">
            <span class="bubble bubble-one"></span>
            <span class="bubble bubble-two"></span>
            <span class="bubble bubble-three"></span>
        </div>
    </div>
</ng-container>
