import { response } from 'express';
import { APP_ID, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IChatHistory, IQuery } from '../../shared/interfaces/query.interface';
import { QueryService } from '../../services/query.service';
import { CustomValidators } from './CustomValidators';
import { Observable, Subject, concatMap, delay, first, from, mergeMap, repeat, switchMap, takeUntil, tap, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { AnalyticsService } from '../../services/analytics.service';


export interface GifMessage {
    text: string;
    image: string;
}
const nonCompliantMessageList: GifMessage[] = [
    { text: "I’m going to need a moment to think about this…", image: "/assets/images/gif/thinking.gif" },
    { text: "Just checking the technology. I'm on it…", image: "/assets/images/gif/phone.gif" },
    { text: "Need a snack while I think.", image: "/assets/images/gif/snack.gif" },
    { text: "Looks like we are going to extra time. Not long now.", image: "" }
];

const compliantMessageList: GifMessage[] = [
    { text: "…Okay. To business. I’ve seen some pretty bad own goals in my time. And this is up there...", image: "/assets/images/gif/head_shake.gif" },
    { text: "I’m going to need a moment to think about this…", image: "/assets/images/gif/thinking.gif" },
    { text: "Can't stop laughing at your friend. Ha. 🤣", image: "/assets/images/gif/laughter.gif" },
    { text: "Just checking the technology. I'm on it…", image: "/assets/images/gif/phone.gif" },
    { text: "Need a snack while I think.", image: "/assets/images/gif/snack.gif" },
    { text: "Looks like we are going to extra time. Not long now.", image: "" }
];
@Component({
    selector: 'app-input-submitter',
    templateUrl: './input-submitter.component.html',
    styleUrl: './input-submitter.component.scss'
})

export class InputSubmitterComponent implements AfterViewInit {
    @Input() latestId!: string;
    @Input() token!: string;
    @Input() testing: boolean = true;
    @Output() newMessage = new EventEmitter<boolean>();
    @ViewChild("inputBox") inputBox?: ElementRef
    stage: number = 1;
    round: number = 1;
    previousQueryID: string = '';
    enabled: boolean = false
    placeholder: string = ""
    loading: Observable<boolean> = this.queryService.loading$;
    query: Observable<any | null> = this.queryService.latestQuery$;
    destroy$: Subject<boolean> = new Subject<boolean>();
    startwait$: Subject<boolean> = new Subject<boolean>();
    submitted = false;
    stop = false;
    malError: boolean = false;
    baseUrl = environment.url + '/api/v1';
    messageForm = this.fb.group({
        message: [null, []]
    });
    validatorsMap: { [key: number]: ValidatorFn[] } = {
        1: [Validators.required, Validators.minLength(1), Validators.maxLength(100)],
        2: [Validators.required, Validators.minLength(1), Validators.maxLength(100)],
        3: [Validators.required, CustomValidators.maxCharacters(40), CustomValidators.noConsecutiveIdenticalLetters(), CustomValidators.noExcessiveConsecutiveCapitalLetters(), CustomValidators.noNumbers(), CustomValidators.allowedSpecialCharacters(), CustomValidators.noExcessiveWhiteSpace(), CustomValidators.noRepeatingWords()],
        4: [Validators.required, CustomValidators.maxCharacters(40), CustomValidators.noConsecutiveIdenticalLetters(), CustomValidators.noExcessiveConsecutiveCapitalLetters(), CustomValidators.noNumbers(), CustomValidators.allowedSpecialCharacters(), CustomValidators.noExcessiveWhiteSpace(), CustomValidators.noRepeatingWords()],
        5: [Validators.required, CustomValidators.maxCharacters(120), CustomValidators.noConsecutiveIdenticalLetters(), CustomValidators.noExcessiveConsecutiveCapitalLetters(), CustomValidators.noExcessiveNumbers(), CustomValidators.containsAlphaNumeric(), CustomValidators.allowedSpecialCharacters(), CustomValidators.noExcessiveWhiteSpace(), CustomValidators.noRepeatingWords()],
        6: [Validators.required, Validators.email],
        7: [Validators.required, CustomValidators.emailVerifications()]
    };

    constructor(
        private queryService: QueryService,
        public fb: FormBuilder,
        public http: HttpClient,
        private userService: UserService,
        private analyticsService: AnalyticsService) {

        this.query.subscribe((data: IQuery) => {
            let newStage = false;
            if (data && data.stage && data.stage !== this.stage) {
                this.stage = data.stage;
                newStage = true;
                if (newStage) {
                    this.updatePlaceholderAndEnableFromStage();
                }
            }
            if (data && data.endloop_ready === true && this.stop === false) {
                this.stop = true;
                this.round++;
                this.queryService.completeEndLoop(data._id).subscribe(val => {
                    this.queryService.updateLatestObserver(val);
                    this.stop = false;
                    this.triggerEndLoopStream(data._id).subscribe(val => {
                        // console.log({ val })
                    });
                });
            }
        });
    }

    ngAfterViewInit(): void {
        this.updatePlaceholderAndEnableFromStage();
    }

    setEnabled(status: boolean) {
        this.enabled = status;
    }
    setPlaceholder(placeholder: string) {
        this.placeholder = placeholder
    }
    getPlaceholder() {
        return this.placeholder
    }
    getEnabled() {
        return this.enabled
    }
    getErrors(): string[] {
        const control = this.messageForm.get('message')
        control?.clearValidators();
        control?.setValidators(this.validatorsMap[this.stage])
        control?.updateValueAndValidity();

        if (control?.errors) {
            console.log(control?.errors)
            const errors: ValidationErrors | null = control.errors;
            if (!errors) {
                return [];
            }
            return Object.values(errors).map(error => error.toString());
        }

        return []
    }
    onFocus() {
        if (!this.getEnabled()) {
            const inputElement = this.inputBox?.nativeElement as HTMLInputElement;
            inputElement.blur();
        }
    }

    updatePlaceholderAndEnableFromStage() {
        switch (this.stage) {
            case 3: {
                this.setEnabled(true)
                this.setPlaceholder("Enter your name");
                return;
            }
            case 4: {
                this.setEnabled(true)
                this.setPlaceholder("Enter your friend's name");
                return;
            }
            case 5: {
                this.setEnabled(true)
                this.setPlaceholder("Enter the own goal");
                return;
            }
            case 6: {
                if (this.userService.isCannesDemo()) {
                    this.setEnabled(false)
                    this.setPlaceholder("")
                } else {
                    this.setEnabled(true)
                    this.setPlaceholder("Enter your email")
                }
                return;
            }
            case 7: {
                this.setEnabled(true)
                this.setPlaceholder("Enter the code")
                return;
            }
            default: {
                this.setPlaceholder("")
                this.setEnabled(false)
            }
        }
    }

    public submitForm(values: any): void {
        const query: IChatHistory | null = this.newIncomingMessage(values);
        if (!query) {
            return;
        }
        this.sendFormData(query).subscribe(response => {
            this.queryService.setLoading(true);
            if (this.stage === 5) {
                this.handleOwnGoal(query, response);
                return;
            } else {
                this.messageForm.reset();
                this.queryService.appendMessageLocal(query).pipe(delay(500)).subscribe(() => {
                    if (this.stage > 5) {
                        this.destroy$.next(true);
                        this.queryService.completeEmailLoop(response._id).subscribe((data) => {
                            //console.log(data)
                            this.queryService.updateLatestObserver(data);
                            this.queryService.checkStatus(this.testing, this.token);
                        });
                        if (this.stage === 7) {
                            console.log(this.stage, { response })
                            if (response.chat_history[response.chat_history.length - 1].content === "please try again") {
                                this.updatePlaceholderAndEnableFromStage();
                                this.queryService.setLoading(false);
                            }
                        }
                        return;
                    }

                    this.queryService.checkStatus(this.testing, this.token);
                });
            }
        }, error => {
            if (this.stage > 4) {
                this.resetIncomingMessage(true);
            } else {
                this.resetIncomingMessage(false);
            }
        });
    }
    // Handle Own Goal
    public handleOwnGoal(query: IChatHistory, response: IQuery): void {
        if (response.chat_history[response.chat_history.length - 1].saftey?.compliance === "Non-Compliant") {
            query.content = "[ Own-Goal Sent. ]"
        }
        this.queryService.appendMessageLocal(query).pipe(delay(2000)).subscribe(userResult => {
            this.queryService.appendMessageLocal({
                content: `Ok, give me a sec…`,
                type: 'text',
                role: "agent",
            }, 4000).subscribe(stage1Result => {
                this.queryService.getLatestMessage(`${response.token}`).pipe(
                    mergeMap(() => this.http.get<IQuery>(`${this.baseUrl}/query/get-token/${response.token}?modifiedOn=${new Date().toISOString()}`)),
                    delay(3000),
                    repeat(10),
                    takeUntil(this.startwait$)
                ).subscribe(waitToStart => {
                    if (waitToStart && waitToStart.response && waitToStart.response != "") {
                        this.waitForCompliance(waitToStart);
                    }
                    console.log("JOSE IS THINKING...Great time to grab a snickers");
                })
            });
        }, error => {
            this.localMessageError();
        });
    }
    // First action after submit form
    newIncomingMessage(values: any): IChatHistory | null {
        this.newMessage.emit(true)
        this.messageForm.get('message')?.clearValidators();
        this.messageForm.get('message')?.setValidators(this.validatorsMap[this.stage])
        this.messageForm.get('message')?.updateValueAndValidity();
        this.malError = false;
        this.submitted = true;
        if (this.stage < 3) {
            return null;
        }
        if (this.messageForm.invalid) {
            return null;
        }
        this.setPlaceholder("")
        this.setEnabled(false);
        this.messageForm.reset();
        return {
            query: values.message,
            content: values.message,
            type: 'text',
            role: "user",
        }
    }
    // action after submit form  and it errors out
    resetIncomingMessage(clearLastMessage: boolean): void {
        this.queryService.setLoading(false);
        this.messageForm.reset();
        this.setPlaceholder("")
        this.setEnabled(true)
        this.malError = true;
        if (clearLastMessage) {
            this.queryService.removeLastMessageLocal();
        }
    }
    triggerWaitingMessageStream(messageList: GifMessage[], id: string): Observable<IQuery> {
        return from(messageList).pipe(
            concatMap((item, index) => timer(index * 5000).pipe(
                tap((item) => { this.queryService.setLoading(true) }),
                delay(1000),
                takeUntil(this.destroy$),
                tap(() => {
                    this.query.subscribe((data: IQuery) => {
                        //console.log({ email: data.appended_email, video: data.video_generated });
                        if ((data && data.appended_email === true) || (data && data.video_generated === true)) {
                            this.destroy$.next(true);
                        }
                    })
                }),
                concatMap(() => {
                    let messageType = 'video';
                    let delayTime: number = 10000;

                    return new Observable((observer) => {
                        this.queryService.appendMessageLocal({
                            query: JSON.stringify(item),
                            content: JSON.stringify(item),
                            type: messageType,
                            role: "agent",
                        }, delayTime, id, messageType, true).subscribe(() => {
                            this.queryService.setLoading(false);
                            observer.next();
                            observer.complete();
                        });
                    });
                }),
            )),
            concatMap(() => this.query),
            tap((response) => {
                if (response) {
                    //console.log({ email: response.appended_email, video: response.video_generated });
                }
                if ((response && response.appended_email === true) || (response && response.video_generated === true)) {
                    this.queryService.updateMessage(response);
                    this.destroy$.next(true);
                    this.queryService.setLoading(false);

                    this.queryService.checkStatus(this.testing, this.token);
                }
            })
        );
    }
    triggerEndLoopStream(id: string): Observable<IQuery> {
        const messageList = [{
            role: 'agent',
            type: 'action',
            content: "snickers",
            time: new Date().toISOString()
        }, {
            role: 'agent',
            type: 'text',
            content: "Got another own goal a friend scored? Tell me their name.",
            time: new Date().toISOString()
        }
        ]
        return from(messageList).pipe(
            concatMap((item, index) => timer(index * (4000)).pipe(
                tap((item) => { console.log({ item }); this.queryService.setLoading(true) }),
                delay(1000),
                concatMap(() => {
                    let delayTime: number = 4000;
                    return new Observable((observer) => {
                        this.queryService.appendMessageLocal(item, delayTime, id, item.type, false).subscribe(() => {
                            this.queryService.setLoading(false);
                            observer.next();
                            observer.complete();
                        });
                    });
                })
            )),
            concatMap(() => this.query),
            tap((response) => {
                this.queryService.setLoading(false);
            })
        );
    }
    handleMaliciousInput(type: string | undefined): void {
        console.log(`Handle all ${type}`);
        // O-G Has mal intent found with search or AI
        this.queryService.resetResponse().subscribe((response) => {
            this.queryService.checkStatus(this.testing, this.token);
            this.startwait$.next(true);
            this.updatePlaceholderAndEnableFromStage();
        })
    }
    waitForCompliance(waitToStart: IQuery): void {
        const ownGoalMessage = waitToStart.chat_history[waitToStart.chat_history.length - 3].saftey;
        const responseMessage = waitToStart.chat_history[waitToStart.chat_history.length - 1].saftey;
        // console.log({ ownGoalMessage, responseMessage })
        const compliance = ownGoalMessage?.compliance;
        const responseCompliance = responseMessage?.compliance;
        //Handle all 'Malicious'
        if (this.isInputType(compliance, 'Malicious') || this.isInputType(responseCompliance, 'Reject')) {
            this.handleMaliciousInput(compliance);
        } else {
            const messageList: GifMessage[] = this.isInputType(compliance, 'Not Compliant') ? nonCompliantMessageList : compliantMessageList;
            this.queryService.checkStatus(this.testing, this.token);
            this.queryService.updateLatestObserver(waitToStart).subscribe(latestResult => {
                this.complianceIsReady(messageList, waitToStart);
            });
        }
    }
    complianceIsReady(messageList: GifMessage[], response: IQuery): void {
        let emailMessage = "You can wait, or you can enter your email address at any time and I'll send you a link when your video message is ready.";

        if (this.userService.isCannesDemo()) {
            emailMessage = "I see you are messaging from Cannes. Just goes to show people make mistakes everywhere. Will get right back to you.";
        }

        this.startwait$.next(true);
        this.queryService.appendMessageLocal({
            role: 'agent', type: "text",
            content: `I've got lots of people messaging me right now so it’ll take a few minutes or so to get back to you with some ‘Special One - to one’ advice for them. ⏳`
        }, 5000, this.latestId).pipe(delay(2000)).subscribe(stage2Result => {
            this.queryService.appendMessageLocal({
                role: 'agent', type: "text",
                content: emailMessage
            }, 5000).pipe(delay(2000)).subscribe(async (result) => {
                this.queryService.setLoading(false);
                this.triggerWaitingMessageStream(messageList, response._id).pipe(takeUntil(this.destroy$)).subscribe((finalCheck) => {
                    this.queryService.checkStatus(this.testing, this.token);
                    this.query.subscribe((data: IQuery) => {
                        if (data !== null) {
                            console.log('Trigger', data.state);
                            this.queryService.setLoading(false)
                        }
                        if (data && data.stage === 7 && data.appended_email === true) {
                            this.destroy$.next(true);
                            this.queryService.updateMessage(data);
                            this.queryService.checkStatus(this.testing, this.token);
                            this.queryService.completeEmailLoop(data._id).subscribe((response) => {
                                this.queryService.updateLatestObserver(response);
                            });
                        }
                    });
                });
            });
        });
    }
    isInputType(compliance: string | undefined, type: string): boolean {
        if (compliance && compliance.toLowerCase() === type.toLowerCase()) {
            return true;
        }
        return false;
    }
    sendFormData(query: IChatHistory) {
        switch (this.stage) {
            case 3:
                this.analyticsService.updateAnalyticsClass("user_name");
                return this.queryService.yourName(this.latestId, query);
            case 4:
                this.analyticsService.updateAnalyticsClass("friends_name");
                return this.queryService.friendName(this.latestId, query);
            case 5:
                if (this.round > 1) {
                    this.analyticsService.updateAnalyticsClass("second_own_goal");
                } else {
                    this.analyticsService.updateAnalyticsClass("friends_own_goal");
                }
                return this.queryService.ownGoal(this.latestId, query);
            case 6:
                this.analyticsService.updateAnalyticsClass("user_email");
                return this.queryService.yourEmail(this.latestId, query);
            case 7:
                this.analyticsService.updateAnalyticsClass("validation_code");
                return this.queryService.yourCode(this.latestId, query);
            default:
                this.analyticsService.updateAnalyticsClass("user_name");
                return this.queryService.yourName(this.latestId, query);
        }
    }
    getGoogleTagClass(): string {
        switch (this.stage) {
            case 3: {
                return "first_name";
            }
            case 4: {
                if (this.round > 1) {
                    return "second_friends_name"
                }
                return "friends_name";
            }
            case 5: {
                if (this.malError) {
                    return "blocked_own_goal_resubmission"
                }
                if (this.round > 1) {
                    return "second_own_goal"
                }
                return "own_goal";
            }
            case 6: {
                return "user_email";
            }
            case 7: {
                return "validation_code";
            }
            default: {
                return ""
            }
        }
    }
    localMessageError(): void {
        this.queryService.setLoading(false);
        this.messageForm.reset();
        this.setPlaceholder("")
        this.setEnabled(true)
        this.malError = true;
        this.queryService.removeLastMessageLocal();
    }
}
