
@if(!this.devMode) {
    <div class="desktop-scan">
        <div class="container">
            <div class="left-column">
                <img style="height: 60%" class="phone" src="assets/images/PhoneScreen.png">
            </div>
            <div class="right-column">
                <h2 class="strong tungsten" >This experience can only</h2>
                <h2 class="strong tungsten" >be enjoyed on your phone</h2>
                <img src="assets/images/qrCode.svg">
            </div>
        </div>
    </div>
}
<div class="simulation">
    <div class="simulation-content" [attr.data-stage]="stage">
        <div class="simulation-container">
            <app-modal-message id="app-modal"></app-modal-message>
            <app-error-message></app-error-message>
            <div class="simulation-contents">
                <ng-container *ngIf="!(initialLoad | async); let initialLoad else loading">
                    <div class="convo-container">
                        @if (stage===1) {

                        <div class="pay-wall">
                            <ng-container *ngIf="dobError$|async; let error">
                                <ng-container *ngIf="error==='init' else badDate">
                                    <div class="header">
                                        <h3 class="uppercase tungsten" style="margin: 0;">Want to speak with José?</h3>
                                    </div>
                                    <div class="text-ine">
                                        <p class="center-text interstate" style="margin: 0;">Are you over 18?</p>
                                    </div>
                                    <hr />
                                    <div class="form"><app-date-picker (save)="dob($event)"></app-date-picker></div>
                                </ng-container>
                                <ng-template #badDate>
                                    <div class="header">
                                        <h3 class="uppercase tungsten">HEY THERE, WE ARE SORRY.</h3>
                                    </div>
                                    <div class="text-ine">
                                        <p class="center-text interstate" style="width:230px; margin: 0;">{{error}}</p>
                                    </div>
                                    <hr />
                                    <a href="https://snickers.com" class="secondary-cta interstate-bold uppercase ">I understand</a>
                                    <hr />
                                </ng-template>
                            </ng-container>
                            <div class="footer">
                                <p class="center-text"><small class="center-text interstate-bold">For more information
                                        about responsible use of our products, please follow the link to the <a
                                            href="https://www.mars.com/about/policies-and-practices/marketing-code"
                                            target="_blank">Mars Marketing Code</a>.</small></p>
                            </div>
                        </div>
                        <div class="pay-wall-overlay"></div>
                        }
                        <div class="chat-block">
                            <div #chatWrapper class="chat-wrapper disable-scrollbars" *ngIf="stage>0">
                                <div class="chat-container">
                                    <div id="header-container" #headerContainer [style]="getHeaderPosition()" class="header-container">
                                        <a href="https://snickers.co.uk">
                                            <img (click)="resetQuery()" src="/assets/images/header-logo.png" alt="snickers" style="width: 60%; margin: 0 auto; display: inline-block; margin-bottom: 20px; margin-top: 30px;" />
                                        </a>
                                        <strong style="margin-bottom: 15px;">
                                            <h2 class="uppercase tungsten">MATE SCORED AN</h2>
                                        </strong>
                                        <strong>
                                            <h1 class="uppercase tungsten">OWN GOAL?</h1>
                                        </strong>
                                        <strong>
                                            <h3 class="uppercase tungsten">LET JOSÉ MOURINHO SEND THEM A MESSAGE</h3>
                                        </strong>
                                    </div>
                                    <div style="margin-top: 5px;" id="message-container">
                                        <app-message-container #messagesContainer (newMessage)="reScroll()"
                                        (saftey)="pushSaftey($event)"
                                        style="padding-bottom: 40px;"></app-message-container>
                                    </div>
                                </div>
                            </div>
                            <div style="background-color: #f8f8f8;">
                                <app-input-submitter style="width: 100%;" style="background-color: #f8f8f8;" [latestId]="latestId" [token]="token" [testing]="testing" [stage]="stage" (newMessage)="reScroll()"></app-input-submitter>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #loading>
                    <div class="loader-container">
                        <div class="loader"><img src="assets/images/snickers_loading.png" /></div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="overlay"></div>
    </div>
</div>