import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { franc, francAll } from 'franc';

export class CustomValidators {

  static maxCharacters(limit: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      return value && value.length > limit ? { maxCharacters: `Maximum ${limit} characters allowed.` } : null;
    };
  }

  static noConsecutiveIdenticalLetters(limit: number = 2): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = new RegExp(`(.)\\1{${limit},}`);
      return regex.test(value) ? { noConsecutiveIdenticalLetters: 'No more than 2 consecutive identical letters allowed.' } : null;
    };
  }

  static noExternalLinks(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = /https?:\/\/[^\s]+/;
      return regex.test(value) ? { noExternalLinks: true } : null;
    };
  }

  static noExcessiveConsecutiveCapitalLetters(limit: number = 3): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = new RegExp(`[A-Z]{${limit + 1},}`);
      return regex.test(value) ? { noExcessiveConsecutiveCapitalLetters: `No more than ${limit} consecutive capital letters allowed.` } : null;
    };
  }

  static noExcessiveNumbers(limit: number = 5): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = new RegExp(`\\d{${limit},}`);
      return regex.test(value) ? { noExcessiveNumbers: `No more than ${limit} consecutive numbers allowed.` } : null;
    };
  }

  static allowedSpecialCharacters(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = /[^a-zA-Z0-9 .,?!‘`’'"\-]/;
      return regex.test(value) ? { allowedSpecialCharacters: `Only . , ? !'‘ - are accepted as special characters.` } : null;
    };
  }

  static noExcessiveWhiteSpace(limit: number = 2): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = new RegExp(`\\s{${limit + 1},}`);
      return regex.test(value) ? { noExcessiveWhiteSpace: `No more than ${limit} consecutive spaces allowed.` } : null;
    };
  }

  static noProfanity(profaneWords: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = new RegExp(`\\b(${profaneWords.join('|')})\\b`, 'i');
      return regex.test(value) ? { noProfanity: 'Profane language is not allowed.' } : null;
    };
  }
  static emailVerifications(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    const regex = new RegExp(`/^\d{6}$/`);
    return regex.test(value) ? { noExcessiveWhiteSpace: `Please enter a valid code.` } : null;
  };
  }
  static noRepeatingWords(limit: number = 4): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (value === null) {
        return null;
      }
      if (!value.trim().includes(' ')) {
        return null;
      }
      const words = value.split(/\s+/);
      const wordCounts = words.reduce((counts, word) => {
        counts[word] = (counts[word] || 0) + 1;
        return counts;
      }, {} as { [key: string]: number });
      const hasRepeatingWord = Object.values(wordCounts).some(count => count >= limit);
      return hasRepeatingWord ? { noRepeatingWords: `No word should be repeated ${limit} or more times.` } : null;
    };
  }

  static noNumbers(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = /\d/;
      return regex.test(value) ? { noNumbers: 'Numbers are not allowed.' } : null;
    };
  }

  static containsAlphaNumeric(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (value === "") {
        return null
      }
      const regex = /[a-zA-Z]/;
      return regex.test(value) ? null : { containsAlphaNumeric: 'Please include letters.' };
    };
  }

  static noSpecialCharacters(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const regex = /[^a-zA-Z\s]/;
      return regex.test(value) ? { noSpecialCharacters: 'Special characters are not allowed.' } : null;
    };
  }

  static englishOnly(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (value) {

        if (value.length < 10) {
          return { englishOnly: 'Message too short.' }
        }
        const results = francAll(value);
        const englishResult = results.find(result => result[0] === 'eng');
        const englishScore = englishResult ? englishResult[1] : 0;
        return (englishScore > 0.75) ? null : { englishOnly: 'English is the only valid input.' };
      }
      return null
    };
  }
}
