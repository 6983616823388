<div class="date-picker">
    <form [formGroup]="form">
        <button class="app-cta interstate-bold uppercase age_gate_btn" (click)="onSubmit(true)"> @if(this.submitted){
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        } @else {
            Yes
        }</button>
        <button [disabled]="this.submitted" class="app-cta interstate-bold uppercase age_gate_btn"  (click)="onSubmit(false)">
           No
        </button>
    </form>
</div>
<p *ngIf="error" class="center-text interstate error"><small>{{error}}</small></p>