import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IChatHistory } from '../../shared/interfaces/query.interface';
import { MessageTypeActionComponent } from './action/action.component';
import { MessageTypeTextComponent } from './text/text.component';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements AfterViewInit {
  constructor() { }
  @Input() messageInput!: IChatHistory;
  @Input() index!: number;
  @Output() newMessage = new EventEmitter<boolean>();
  @Input() test: boolean | undefined = false;
  @Output() saftey: EventEmitter<IChatHistory> =
    new EventEmitter<IChatHistory>();
  ngAfterViewInit(): void {
    this.newMessage.emit(true);
  }
  pushSaftey(message: IChatHistory): void {
    this.saftey.emit(message);
  }
}
