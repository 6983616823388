

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessageService } from './error-message.component.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
    faExclamationTriangle=faExclamationTriangle;
    messages: Observable<{type:string, message:string}[] | null> = this.errorMessageService.errorMessages$;
  constructor(
    private errorMessageService: ErrorMessageService
  ) {
  }
  ngOnInit(): void {
  }

}
