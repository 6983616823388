<div class="modal-background animate__animated animate__fadeIn" [ngClass]="this.modalMessageService.modalType === 'video'?'video-background':''" *ngIf="active | async; let active">
  @if (this.modalMessageService.loading) {
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  }
  <div class="animate__animated animate__backInDown" [ngClass]="this.modalMessageService.modalType === 'video'?'video-container':'modal-container'">
      @switch (this.modalMessageService.modalType) {
        @case ("terms") {
          <div class="header">
            <h2 class="center-text tungsten">Terms & Conditions</h2>
          </div>
            <div class="modal-message__content">
              <p><strong>Terms and Conditions of Use from Mars Wrigley Confectionary UK Limited.</strong></p>
              <ul>
                <li>1. Snickers use Generative AI to create a video based on the information you provide.</li>
                <br>
                <li>2. You're responsible for the input you give about your friend, please be kind, truthful and within the law.</li>
                <br>
                <li>3. Mars will own the license to the tool and the video that is generated and will delete it if the content is illegal or goes against the company and brand values.</li>
                <br>
                <li>4. Only those 18 years old and above, who live in the UK can use the tool.</li>
                <br>
                <li>5. The content generated does not represent Mars or José Mourinho’s views.</li>
                <br>
                <li>6. Use discretion before relying on, publishing or otherwise using content provided by this tool.</li>
              </ul>
              <br>
              <p>See full <span (click)="fullTerms()" style="color:navy" class="underline">Terms and Conditions</span>.</p>
            </div>
          <div class="footer">
            <button class="secondary-cta" (click)="closeModal()">Close</button>
          </div>
        }
        @case ("video") {
          <div [ngStyle]="this.modalMessageService.loading ? {'visibility':'hidden'}:{'visibility':'visible'}" class="level1">
              <div class="level2">
                  <div class="player-container">
                      <button class="close-button" (click)="closeModal()"><img src="/assets/images/close.svg"></button>
                      <mux-player id="modal-player" [ngClass]="this.modalMessageService.intro ? 'intro_video_played' : 'own_goal_video_played' " thumbnail-time="0" accent-color="#FF0000" placeholder="/assets/images/bumper.png"></mux-player>
                      @if(!this.modalMessageService.intro) {
                        <video id="outro" muted playsinline style="object-fit: cover" class="video-background hidden" (click)="replay()">
                          <source src="assets/images/outro.mp4" type="video/mp4">
                        </video>
                      }
                  </div>
                  @if(!this.modalMessageService.intro && !this.userService.isCannesDemo()){
                    <!-- <strong class="tungsten disclaimer">Disclaimer: These videos of Jose were created using AI</strong> -->
                    <button class="app-cta btn-big-text tungsten share_video_from_own_goal" (click)="share(modalMessageService.id)">SHARE VIDEO</button>
                  }
              </div>
          </div>
        }
      }
  </div>
</div>