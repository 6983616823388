<div class="error-container">
  @if(this.submitted && messageForm.get('message')?.dirty && messageForm.get('message')?.errors) {
  @for(error of getErrors(); track $index) {
  @if (error !== "true") {
  <p class="text-danger form-error">{{error}}</p>
  }
  }
  }
  @if (malError) {
  <p class="text-danger form-error">Hmmm... try rewording.</p>
  }
</div>
<div class="input-area" [ngClass]="{'partial-opacity': !getEnabled()}">
  <form [formGroup]="messageForm" (ngSubmit)="submitForm(messageForm.getRawValue())">
    <input #inputBox type="text" class="query" (focus)="onFocus()" [placeholder]="this.getPlaceholder()"
      [tabIndex]="getEnabled() ? 1 : -1" [disabled]="!getEnabled()" [readonly]="!getEnabled()" formControlName="message"
      maxlength="600" />
    <button [disabled]="!getEnabled()" class="app-cta" [class]="getGoogleTagClass()"
      [ngClass]="{'cta-disabled': !getEnabled()}">
      <img src="/assets/images/send.png" alt="send" />
    </button>
  </form>
</div>