import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IChatHistory } from '../../../shared/interfaces/query.interface';
import { ModalMessageService } from '../../modal-message/modal-message.component.service';
import { GifMessage } from '../../input-submitter/input-submitter.component';
import { AnalyticsService } from '../../../services/analytics.service';
import { VideoService } from '../../../services/video.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-message-video',
  templateUrl: './video.component.html',
  styleUrls: ['../message.component.scss', './video.component.scss']
})
export class MessageTypeVideoComponent {
  @Input() messageInput!: IChatHistory;
  @Input() index!: number;
  @Input() test: boolean = false;
  @ViewChild('appModal') appModal?: ElementRef;

  @Output() saftey: EventEmitter<IChatHistory> =
    new EventEmitter<IChatHistory>();
  pushSaftey(message: IChatHistory): void {

    this.saftey.emit(message);
  }

  constructor(private modalMessageService: ModalMessageService, private analyticsService: AnalyticsService, private videoService: VideoService) { }

  isGifMessage(): boolean {
    try {
      JSON.parse(this.messageInput.content) as GifMessage
      return true;
    } catch (e) {
      return false
    }
  }

  getGifImage(): string {
    const gifMessageData = JSON.parse(this.messageInput.content) as GifMessage
    return gifMessageData.image
  }

  getGifText(): string {
    const gifMessageData = JSON.parse(this.messageInput.content) as GifMessage
    return gifMessageData.text
  }

  openModal() {
    if (this.messageInput.mux_playback_ids) {
      if (this.index === 0) {
        this.modalMessageService.intro = true;
      } else {
        this.modalMessageService.intro = false;
        this.analyticsService.updateAnalyticsClass("play_video")
      }
      this.modalMessageService.modalType = "video"
      if (this.index !== 0) {
        this.videoService.getVideoByMux(this.messageInput.mux_playback_ids[0].id).pipe(first()).subscribe(video => {
          if (!video || !video?.is_active === true) {
            this.modalMessageService.setPlaybackID('UZ01aGqCfkSVgq2nD6esh01qxJ5Q1Z01VJQA9dFPWfyNH4');
            this.modalMessageService.setActive(true)
          } else {
            // @ts-ignore
            this.modalMessageService.setPlaybackID(this.messageInput.mux_playback_ids[0].id);
            this.modalMessageService.setActive(true)
          }
        });
        return;
      } else {
        this.modalMessageService.setPlaybackID(this.messageInput.mux_playback_ids[0].id)
        this.modalMessageService.setActive(true)
      }

    }
  }
}
