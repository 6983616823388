import { ModalMessageService } from './modal-message/modal-message.component.service';
import { CookieService } from 'ngx-cookie-service';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, concatMap, delay, from, Observable, Subject, Subscription, takeUntil, tap, timer } from 'rxjs';
import { faCopy, faHashtag, faTag } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { QueryService } from '../services/query.service';
import { PromptService } from '../services/prompt.service';
import { IChatHistory, IQuery } from '../shared/interfaces/query.interface';
import { AnalyticsService } from '../services/analytics.service';

@Component({
    selector: 'app-simulation-component',
    templateUrl: './simulation.component.html',
    styleUrls: ['./simulation.component.scss']
})
export class SimulationComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    @Output() saftey: EventEmitter<IChatHistory> =
        new EventEmitter<IChatHistory>();
    @Input() testing: boolean = true;
    @Input() devMode: boolean = false;
    @ViewChild('chatWrapper') chatWrapper!: ElementRef;
    headerPosition: 'position: absolute' | 'position: relative' | 'position: sticky' = 'position: absolute'
    throttleActive: boolean = false;
    throttleTime: number = 1500;
    data: any;
    faTag = faTag;
    faHashtag = faHashtag;
    faCopy = faCopy;
    stage: number = 1;
    initialLoad: Observable<any | null> = this.queryService.initialLoad$;
    query: Observable<any | null> = this.queryService.latestQuery$;
    token: string = this.route.snapshot.params['id'];
    latestId!: string;
    private $dobError: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('init');
    public dobError$: Observable<string | null> = this.$dobError.asObservable();
    dobError: string | null = null;
    destroy$: Subject<boolean> = new Subject<boolean>();
    private pollSubscription: Subscription = new Subscription;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private queryService: QueryService,
        private promptService: PromptService,
        private modalMessageService: ModalMessageService,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private analyticsService: AnalyticsService
    ) {
    }
    ngOnInit(): void {
        console.log('SIMULATION TOKEN', this.token)
        this.data = this.route.snapshot.data['data'];
        this.$dobError.next('init');
        this.query.subscribe((data: IQuery) => {
            this.token = data?.token;
            this.latestId = data?._id;
            if (data && data.stage) {
                console.log("stage: ", data.stage);
                this.stage = data.stage;
            }
            if (data && data.chat_history.length >= 3) {
                this.headerPosition = 'position: relative'
            }
            if (data && data.chat_history.length >= 2) {
                this.headerPosition = 'position: sticky'
            }

        });
    }
    ngAfterViewInit() {
        this.reScroll();
    }

    ngOnDestroy() {
        if (this.pollSubscription) {
            this.pollSubscription.unsubscribe();
        }
    }
    getHeaderPosition(): string {
        return this.headerPosition
    }
    reScroll() {
        try {
            this.chatWrapper.nativeElement.scrollTo({
                top: this.chatWrapper.nativeElement.scrollHeight,
                behavior: 'smooth'
            });
        } catch (err) { }
    }
    stageChange(stage: number) {
        this.stage = stage;
    }
    ngAfterViewChecked(): void {
        this.handleOverlap()
    }
    handleOverlap() {
        const header = document.getElementById("header-container")
        const messages = document.getElementById("message-container")

        if (header && messages) {
            const overlap = this.areOverlapping(header, messages);
            if (overlap) {
                this.headerPosition = 'position: relative'
            }
        }
    }
    areOverlapping(div1: HTMLElement, div2: HTMLElement): boolean {
        const rect1 = div1.getBoundingClientRect();
        const rect2 = div2.getBoundingClientRect();

        // Check for overlap
        return !(
            rect1.right < rect2.left ||
            rect1.left > rect2.right ||
            rect1.bottom < rect2.top ||
            rect1.top > rect2.bottom
        );
    }
    dob(data: { dob: string }): void {
        if (this.throttleActive) {
            return;
        }
        this.throttleActive = true;
        setTimeout(() => {
            this.throttleActive = false;
        }, this.throttleTime);
        const today = new Date();
        const dob = new Date(data.dob);
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
            age--;
        }
        if (age < 18 || age > 107) {
            this.$dobError.next("I am sorry, but you need to be older to use this experience.");
        } else {
            this.userService.updateDOB(data.dob).subscribe(
                () => {
                    this.queryService.setStage(this.latestId, 2).pipe(takeUntil(this.destroy$)).subscribe(query => {
                        this.queryService.updateLatestObserver(query).subscribe(() => {
                            this.stageChange(2);
                            this.analyticsService.updateAnalyticsClass("age_gate")
                            if (query && query.chat_history.length <= 1) {
                                this.destroy$.next(true);
                                this.reScroll();
                                const queries = [
                                    {
                                        role: 'agent',
                                        type: 'text',
                                        content: "Hey. José Mourinho here. So your friend has scored an own goal? I can help.",
                                        time: new Date().toISOString()
                                    },
                                    {
                                        role: 'agent',
                                        type: 'action',
                                        content: "terms",
                                        time: new Date().toISOString()
                                    }
                                ];
                                this.triggerWaitingMessageStream(queries, this.latestId).subscribe(val => {
                                    // console.log({ val })
                                });
                            }
                        });
                    });
                }
            );
        }
    }
    public resetQuery(isTest?: boolean): void {
        this.router.navigate(['/']);
    }
    public copyToClipboard(text: string): void {
        // this.toastService.newToast({message:'Copied to clipboard', type:'success'});
        this.toastr.success('Copied', 'Copying to clipboard');
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        } else {
            // Clipboard API not supported, fallback to alternative method
            // Implement your own logic here
        }
    }
    triggerWaitingMessageStream(messageList: { query?: string, content: string, type: string, role: string }[], id: string): Observable<IQuery> {
        return from(messageList).pipe(
            concatMap((item, index) => timer(index * (4000)).pipe(
                tap((item) => { console.log({ item }); this.queryService.setLoading(true) }),
                delay(1000),
                concatMap(() => {
                    let delayTime: number = 4000;
                    return new Observable((observer) => {
                        this.queryService.appendMessageLocal(item, delayTime, id, item.type, false).subscribe(() => {
                            this.queryService.setLoading(false);
                            this.reScroll();
                            observer.next();
                            observer.complete();
                        });
                    });
                })
            )),
            concatMap(() => this.query),
            tap(() => {
                this.queryService.setLoading(false);
            })
        );
    }
    pushSaftey(message: IChatHistory): void {
        this.saftey.emit(message);
    }
    saveQuery(event: { _id: string, query: string, change: string; }): void {
        this.promptService.updateHistory(event._id, event.query, event.change).subscribe(response => {
            this.toastr.success('Saved', 'updated successfully');
        }, () => {
            this.toastr.error('Error', 'unable to save');
        });
    }


}