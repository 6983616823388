import { throttleTime } from 'rxjs/operators';
import { QueryService } from './../../../services/query.service';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IChatHistory } from '../../../shared/interfaces/query.interface';
import { ModalMessageService } from '../../modal-message/modal-message.component.service';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'app-message-action',
  templateUrl: './action.component.html',
  styleUrls: ['../message.component.scss','./action.component.scss']
})
export class MessageTypeActionComponent implements OnInit {
  @Input() messageInput!: IChatHistory;
  throttleActive: boolean = false;
  throttleTime: number = 1000;

  ngOnInit(): void {
      // Remove the console.log(this.messageInput.type) line from here
  }
  getMessageID(): string {
    if (this.messageInput._id) {
      return this.messageInput._id
    } else {
      return ""
    }
  }
  showTerms() {
    //Pop up modal here
    this.modal.modalType = "terms"
    this.modal.setActive(true);
  }

  declineTerms(id:string, e:Event) {
    if (this.throttleActive) {
      return;
    }
    this.throttleActive = true;
    setTimeout(() => {
      this.throttleActive = false;
    }, this.throttleTime);
    e.preventDefault();
    e.stopPropagation();
    // preventDefault(e);
    console.log("acceptTerms", id);
    // Add decline here
    this.queryService.acceptTerms(false, id);

  }

  acceptTerms(id:string, e:Event){
    const accepts = document.querySelectorAll("[id=accept-button]");
    const declines = document.querySelectorAll("[id=decline-button]");

    accepts.forEach((accept) => {
      accept.setAttribute("disabled", "true")
      accept.classList.add("opac")
    })
    declines.forEach((decline) => {
      decline.setAttribute("disabled", "true")
      decline.classList.add("opac")
    });

    if (this.throttleActive) {
      return;
    }
    this.throttleActive = true;
    setTimeout(() => {
      this.throttleActive = false;
    }, this.throttleTime);
    e.preventDefault();
    e.stopPropagation();
    console.log("acceptTerms", id);
    // Add accept here
    this.queryService.acceptTerms(true, id);
  }

  watchLive(){

  }

  getSnickers(){
    this.analyticsService.updateAnalyticsClass("get_snicker_coupon")
    window.open("https://www.snickersowngoalcoupon.co.uk/", "_blank");
  }

  constructor(
    private modal:ModalMessageService,
    private queryService:QueryService,
    private analyticsService: AnalyticsService
  ){}

}
function preventDefault(e: Event) {
  throw new Error('Function not implemented.');
}

