<div class="message-container" [ngClass]="messageInput.role === 'user'?'user':'agent'">
  @if(this.messageInput.role === "user" && this.messageInput.type !== "hidden"){
  <img src="/assets/images/mourinho_user.png" alt="Me">
  } @else if(this.messageInput.type !== "hidden"){
  <img src="/assets/images/jose_user.png" alt="Jose Mourinho">
  }
  <div class="message-component">
    @switch (this.messageInput.type) {
    @case ('video') {
    <app-message-video [index]="this.index" [messageInput]="this.messageInput" [test]="test||false"
      (saftey)="pushSaftey($event)"></app-message-video>
    }
    @case ('hidden') {
    <app-message-video [index]="this.index" [messageInput]="this.messageInput" [test]="test||false"
      (saftey)="pushSaftey($event)"></app-message-video>
    }
    @case ('text') {
    <app-message-text [messageInput]="this.messageInput" (saftey)="pushSaftey($event)"></app-message-text>
    }
    @case ('action') {
    <app-message-action [messageInput]="this.messageInput"></app-message-action>
    }
    }
  </div>
</div>