import { CookieService } from 'ngx-cookie-service';
import { ChatService } from './chat.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, ReplaySubject, Subject, Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, delay, first, map, takeUntil, takeWhile } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IQuery } from '../shared/interfaces/query.interface';
import { IPrompt } from '../shared/interfaces/prompt.interface';
import { QueuePollClass } from '../shared/classes/queue-poll.class';
import { IVideo } from '../shared/interfaces/video.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  baseUrl = environment.url + '/api/v1';
  // public isLoading: ReplaySubject<boolean> = new ReplaySubject(1);
  private $latestVideo: BehaviorSubject<IVideo | null> = new BehaviorSubject<IVideo | null>(null);
  public latestVideo$: Observable<IVideo | null> = this.$latestVideo.asObservable();
  private $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.$loading.asObservable();
  public destroy$: Subject<boolean> = new Subject<boolean>();
  private videoSubscription: Subscription | null = null;
  constructor(
    public http: HttpClient,
    private chatService: ChatService,
    private cookieService: CookieService
  ) {
  }
  setLoading(value: boolean): void {
    this.$loading.next(value);
  }
  destroyVideo() {
    this.$latestVideo.next(null);
  }

  loadVideo(videoId: string): void {
    this.setLoading(true);
    this.unsubscribeVideo();
    this.videoSubscription = this.getVideoByMux(videoId).pipe(delay(1000),
      takeUntil(this.destroy$)).subscribe((data: IVideo) => {
        this.$latestVideo.next(data);
        this.destroy$.next(true);
        this.setLoading(false);
      })
  }
  unsubscribeVideo() {
    if (this.videoSubscription) {
      this.videoSubscription.unsubscribe();
      this.videoSubscription = null;
    }
  }
  getVideoByMux(id: string): Observable<IVideo> {
    const url = `${this.baseUrl}/video/mux/${id}?modifiedOn=${new Date().getTime()}`;
    return this.http
      .get<IVideo>(url)
      .pipe(catchError(this.handleError))
  }
  getRandomWhatsAppURL(): Observable<{ url: string }> {
    const url = `${this.baseUrl}/twilio/generate`;
    return this.http
      .get<{ url: string }>(url)
      .pipe(catchError(this.handleError))
  }
  getLatestMessage(): Observable<IQuery> {
    const url = `${this.baseUrl}/query/latest`;
    return this.http
      .get<IQuery>(url)
      .pipe(catchError(this.handleError))
  }
  handleError(error: any): Observable<any> {
    return throwError(error.error || 'Server error');
  }
}

