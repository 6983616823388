

import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ModalMessageService } from './modal-message.component.service';
import { Observable } from 'rxjs';
import { IChatHistory } from '../../shared/interfaces/query.interface';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../services/analytics.service';
import { VideoService } from '../../services/video.service';
import { IVideo } from '../../shared/interfaces/video.interface';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements AfterViewChecked {
  faExclamationTriangle = faExclamationTriangle;
  active: Observable<boolean> = this.modalMessageService.active$;
  video: Observable<IVideo | null> = this.videoService.latestVideo$;
  loading: Observable<boolean> = this.videoService.loading$;
  constructor(
    public modalMessageService: ModalMessageService,
    public userService: UserService,
    private analyticsService: AnalyticsService,
    private videoService: VideoService,
    private router: Router
  ) {
  }

  fullTerms() {
    this.router.navigate(['/terms']);
  }

  ngAfterViewChecked(): void {
    if (this.modalMessageService.modalType === "video") {
      // move
      if (!document.getElementById("modal-player")?.getAttribute("playback-id")) {
        this.loadMuxVideo();
      }
    }
  }
  loadMuxVideo(): void {
    const modalPlayer = document.getElementById("modal-player")
    const stillFrame = document.getElementById("outro") as HTMLVideoElement
    modalPlayer?.setAttribute('playback-id', this.modalMessageService.id);
    modalPlayer?.addEventListener('loadedmetadata', () => {
      this.modalMessageService.loading = false;
    });
    modalPlayer?.addEventListener('ended', () => {
      if (this.modalMessageService.intro) {
        this.modalMessageService.closeModal();
      } else {
        stillFrame?.classList.remove("hidden");
        modalPlayer?.classList.add("hidden");
        stillFrame?.play();
      }
    });
  }
  share(muxID: string): void {
    if (navigator && navigator.share) {
      this.analyticsService.updateAnalyticsClass("click_share");
      navigator.share({ url: `${environment.url}/own-goal/?id=${muxID}` });
    }
  }
  replay() {
    const modalPlayer = document.getElementById("modal-player")
    const stillFrame = document.getElementById("outro") as HTMLVideoElement

    modalPlayer?.classList.remove("hidden");
    stillFrame?.classList.add("hidden");
  }
  closeModal(): void {
    this.analyticsService.updateAnalyticsClass("video_close");
    this.modalMessageService.closeModal();
  }
}
