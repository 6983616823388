<div class="message-container animate__animated animate__bounceIn" [ngClass]="{'user': this.messageInput.role === 'user'}">
  @if(this.messageInput.role !== 'user') {
    <img src="/assets/images/MsgTailJose.svg" class="tail-svg-jose">
  } @else {
    <img src="/assets/images/MsgTailUser.svg" class="tail-svg-user">
  }
  @if(this.messageInput.saftey?.compliance === "Non-Compliant") {
    <div class="message-cover" [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'" (click)="pushSaftey(messageInput)">[ Own-Goal Sent. ]</div>
  } @else {
    <div class="message-cover" [ngClass]="this.messageInput.role !== 'user' ? 'message-received' : 'message'" (click)="pushSaftey(messageInput)">{{this.messageInput.content}}</div>
  }
</div>