import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QueryService } from '../../services/query.service';
import { Observable } from 'rxjs';
import { IChatHistory, IQuery } from '../../shared/interfaces/query.interface';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-message-container',
  templateUrl: './message.container.component.html',
  styleUrls: ['./message.container.component.scss']
})
export class MessageContainerComponent implements OnInit {
  query: Observable<IQuery | null> = this.queryService.latestQuery$;
  @Output() newMessage = new EventEmitter<boolean>();
  @Output() saftey: EventEmitter<IChatHistory> =
    new EventEmitter<IChatHistory>();
  constructor(private queryService: QueryService) { }
  loading: Observable<boolean> = this.queryService.loading$;
  ngOnInit(): void {
    this.query.subscribe((query) => {
    });
    this.loading.subscribe((isLoading) => {
      if (isLoading) {
        setTimeout(() => { this.newMessage.emit(true) }, 10)
      }
    })
  }
  newMessageCallback() {
    this.newMessage.emit(true);
  }
  pushSaftey(message: IChatHistory): void {
    this.saftey.emit(message);
  }
}
