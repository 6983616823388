import { MessageComponent } from './messages/message.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Injectable } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { SimulationComponent } from './simulation.component';
import { QueryService } from '../services/query.service';
import { ChatService } from '../services/chat.service';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ErrorMessageService } from './error-message/error-message.component.service';
import { MessageContainerComponent } from './message-container/message.container.component';
import { ModalMessageComponent } from './modal-message/modal-message.component';
import { MessageTypeActionComponent } from './messages/action/action.component';
import { MessageTypeTextComponent } from './messages/text/text.component';
import { MessageTypeVideoComponent } from './messages/video/video.component';
import { InputSubmitterComponent } from './input-submitter/input-submitter.component';
import { PreventDoubleClickDirective } from '../shared/directives/throttleclicks';

@NgModule({
  declarations: [
    SimulationComponent,
    DatePickerComponent,
    ErrorMessageComponent,
    MessageComponent,
    ModalMessageComponent,
    MessageContainerComponent,
    MessageTypeActionComponent,
    MessageTypeTextComponent,
    MessageTypeVideoComponent,
    InputSubmitterComponent
  ],
  providers: [ QueryService, ChatService, ErrorMessageService, PreventDoubleClickDirective],
  imports: [
    CommonModule,
    ToastrModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  exports: [
    MessageComponent,
    SimulationComponent,
    DatePickerComponent,
    ModalMessageComponent,
    ErrorMessageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SimulationModule { }
