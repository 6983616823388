import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IChatHistory } from '../../../shared/interfaces/query.interface';

@Component({
  selector: 'app-message-text',
  templateUrl: './text.component.html',
  styleUrls: ['../message.component.scss', './text.component.scss']
})
export class MessageTypeTextComponent {
  @Input() messageInput!: IChatHistory;
  @Output() saftey: EventEmitter<IChatHistory> =
    new EventEmitter<IChatHistory>();
  pushSaftey(message: IChatHistory): void {
    this.saftey.emit(message);
  }
}
