import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalMessageService {
    private $active: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public active$: Observable<boolean> = this.$active.asObservable();

    modalType?: "terms" | "video"
    intro: boolean = false;
    loading: boolean = true;
    id: string = "";

    constructor() { }
    setActive(isActive:boolean): void {
        this.loading = true
        this.$active.next(isActive);
    }

    setPlaybackID(id: string){
        this.id = id;
    }

    closeModal(): void {
        this.$active.next(false);
    }
}

