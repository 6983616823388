<div class="error-messages" *ngIf="messages | async; let messages">
    <div class="collection">
        <div class="error-message animate__animated  animate__backInDown" *ngFor="let toast of messages">
            <ng-container>
                <div class="{{toast.type}} message__icon">
                    <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                </div>
            </ng-container>
            <div class="error-message__content">
                <p>{{toast.message}}</p>
            </div>
        </div>
    </div>
</div>